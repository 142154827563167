import React from 'react';

function SectionWrapper({ children, isFirstSection, noPadding }) {
  return (
    <div
      className={`section-wrapper__container container ${
        isFirstSection ? 'section-wrapper__container-first-section' : 
        noPadding ? 'section-wrapper__container-no-padding' : ''
      }`}
    >
      <div className="section-wrapper__wrapper">{children}</div>
    </div>
  );
}

export default SectionWrapper;
