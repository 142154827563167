import React from 'react';
import { SecondaryDescription } from './Typography';
import chatIcon from '../assets/images/icons/other/chat-icon.png';
import DownloadAppWidget from '../assets/images/icons/other/download-app-widget-icon.inline.svg';
import { useMemo } from 'react';
import { useContext } from 'react';
import { LayoutContext } from './Layout';

function ContactWidget({ handleClick, title, downloadIcon }) {
  const { cookieAgree } = useContext(LayoutContext)

  const widgetClassName = useMemo(() => {
    const basicClassName = "contact-widget"

    return cookieAgree ? basicClassName : `${basicClassName} ${basicClassName}-cookie`
  }, [cookieAgree])

  return (
    <div className={widgetClassName} onClick={handleClick}>
      {downloadIcon ? (
        <DownloadAppWidget />
      ): (
        <img src={chatIcon} />
      )}
        <SecondaryDescription>{title}</SecondaryDescription>
    </div>

  );
}

export default ContactWidget;
