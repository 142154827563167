import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { SPRIVE_API_BASE_URL, SPRIVE_API_KEY } from '../../../constants';
import Button from '../../Button';
import ModalsFlow from '../../Modals/ModalsFlow';
import { SecondaryDescription } from '../../Typography';
import DealHighlightsItem from './DealHighlightsItem';

function DealHighlights({ allDeals, dealHighlightsData, selectedRow, selectedDeal, setSelectedDeal, productId }) {
  const [modalFlow, setModalFlow] = useState(false);
  const [addFeeToLoan, setAddFeeToLoan] = useState(false)

  const saveDealToDB = () => {
    if(!allDeals || typeof window === 'undefined') return
    const selectedDealData = allDeals[selectedDeal.rowIndex].products[selectedDeal.dealIndex]
    const body = {
      product_recommended_id: parseInt(productId),
      product_recommended_product_uuid: selectedDealData.product_recommended_product_uuid,
      add_fee_to_loan: addFeeToLoan
    }

    const partnerParam = new URLSearchParams(window.location.search).get("partner")

    if(partnerParam) {
      body.partner = partnerParam
    }

    console.log(JSON.stringify(body), localStorage.getItem("uuid"))

    fetch(SPRIVE_API_BASE_URL + `product-selector-selected-product?uuid=${localStorage.getItem("uuid")}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        api_key: SPRIVE_API_KEY
      },
      body: JSON.stringify(body),
    })
    .then(res => res.json())
    .then(data => console.log(data))
  }

  const handleDealSelection = useCallback(() => {
    saveDealToDB()
    setModalFlow(true)
  }, [selectedDeal, allDeals, addFeeToLoan])

  const generateDealHighlights = useCallback(() => {
    if (!dealHighlightsData) return;

    const components = [];

    dealHighlightsData.forEach((item, index) => {
      const isSelected = selectedDeal.rowIndex === selectedRow && selectedDeal.dealIndex === index;
      components.push(
        <DealHighlightsItem
          key={index}
          item={item}
          setIsSelected={() => setSelectedDeal(index)}
          isSelected={isSelected}
          setAddFeeToLoan={setAddFeeToLoan}
        />
      );
    });

    return components;
  }, [dealHighlightsData, selectedRow, selectedDeal, addFeeToLoan]);

  return (
    <div className="highlights__wrapper">
      <div className="highlights__items-container">{generateDealHighlights()}</div>
      <div className="highlights__button-container">
        <Button
          buttonText="Speak to an advisor"
          height="68px"
          handleClick={handleDealSelection}
        />
        <SecondaryDescription>
          Your eligibility to this product will be verified by an FCA approved advisor
        </SecondaryDescription>
      </div>
      <ModalsFlow isFirstOpened={modalFlow} setIsFirstOpened={setModalFlow} firstModalIndex={1} />
    </div>
  );
}

export default DealHighlights;
