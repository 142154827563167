import React, { useMemo } from 'react';
import blankImage from '../../assets/images/icons/other/blank-image.png';
import { MainDescription } from '../Typography';

function BasicDealCard({ children, isSelected, setSelectedDeal, lenderName, lenderLogo }) {
  const containerClassName = useMemo(() => {
    const basicClassName = 'deals__item-container';

    return isSelected ? `${basicClassName} ${basicClassName}-selected` : basicClassName;
  }, [isSelected]);

  const itemClassName = useMemo(() => {
    const basicClassName = 'deals__item';

    return isSelected ? `${basicClassName} ${basicClassName}-selected` : basicClassName;
  }, [isSelected]);

  return (
    <div className={containerClassName}>
      <div className={itemClassName} onClick={setSelectedDeal}>
        <div className="deals__item-wrapper">
          <div className="deals__item-image-container">
            {lenderLogo && (
              <div className="deals__item-image">
                <img src={lenderLogo || blankImage} />
              </div>
            )}
            <MainDescription>{lenderName}</MainDescription>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default BasicDealCard;
