import React from 'react';
import { SecondaryDescription, SecondaryTitle } from '../../Typography';
import BasicDealCard from '../BasicDealCard';
import Switch from 'react-switch';
import { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';

function DealHighlightsItem({ item, isSelected, setIsSelected, setAddFeeToLoan }) {
  const [checked, setChecked] = useState(false);
  const hasLoanFee = useMemo(() => item.total_cost_over_initial_period_with_loan_fee, [item])

  useEffect(() => {
    setAddFeeToLoan(checked)
  }, [checked])

  return (
    <BasicDealCard
      isSelected={isSelected}
      setSelectedDeal={setIsSelected}
      lenderName={item.lender_name}
      lenderLogo={item.lender_logo}
    >
      <div className="highlights__item-info">
        <div className="highlights__item-table__container">
          <div className="highlights__item-table">
            <SecondaryTitle>Initial Period</SecondaryTitle>
            <SecondaryDescription>{item.year_label}</SecondaryDescription>
          </div>
          <div className="highlights__item-table">
            <SecondaryTitle>Initial Rate</SecondaryTitle>
            <SecondaryDescription>{item.initial_interest_rate}</SecondaryDescription>
          </div>
          <div className="highlights__item-table">
            <SecondaryTitle>Monthly Payment</SecondaryTitle>
            <SecondaryDescription>
              {checked ? item.initial_monthly_payment_with_fee : item.initial_monthly_payment}
            </SecondaryDescription>
          </div>
          <div className="highlights__item-table">
            <SecondaryTitle>Lender Fees</SecondaryTitle>
            <SecondaryDescription>{item.lender_fees}</SecondaryDescription>
          </div>
        </div>
          {hasLoanFee && (
            <div className="highlights__fees-container">
                <SecondaryDescription>Add fees to loan</SecondaryDescription>
                <Switch
                checked={checked}
                onChange={() => setChecked((prevState) => !prevState)}
                uncheckedIcon={false}
                checkedIcon={false}
                width={42}
                offColor="#4C5D89"
                activeBoxShadow={false}
                />
            </div>
          )}
        <div className={`highlights__price-container ${!hasLoanFee ? "highlights__price-container__no-loan" : ""}`}>
          <SecondaryDescription>Total cost over initial period (inc. fees)</SecondaryDescription>
          <SecondaryTitle>
            {checked
              ? item.total_cost_over_initial_period_with_loan_fee
              : item.total_cost_over_initial_period_without_loan_fee}
          </SecondaryTitle>
        </div>
      </div>
    </BasicDealCard>
  );
}

export default DealHighlightsItem;
