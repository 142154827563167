import { Link } from 'gatsby';
import React from 'react';
import { useState } from 'react';
import useWindow from '../../../hooks/useWindow';
import ModalsFlow from '../../Modals/ModalsFlow';
import { SecondaryTitle } from '../../Typography';
import CustomHeadingSection from '../CustomHeadingSection';
import SectionWrapper from '../SectionWrapper';
import NavArrow from '../../../assets/images/icons/other/nav-arrow.inline.svg';
import Button from '../../Button';
import { useCallback } from 'react';

function DealsHeading({ leftContent, links, image }) {
  const { isDesktop } = useWindow();
  const [modalFlow, setModalFlow] = useState(false);

  const generateLinks = useCallback(() => {
    const components = [];

    links.forEach((link, index) => {
      components.push(
        <>
        {link.external ? (
            <>
                <a
                    key={index}
                    href={link.leadsTo}
                    target="_blank"
                    className={link.isActive ? 'deals-section-nav-active' : ''}
                >
                    {link.title}
                </a>
                {index !== links.length - 1 && <NavArrow key={index} />}
            </>
        ) : (
            <>
            <Link
                key={index}
                to={link.leadsTo}
                className={link.isActive ? 'deals-section-nav-active' : ''}
            >
                {link.title}
            </Link>
            {index !== links.length - 1 && <NavArrow key={index} />}
            </>
        )}
        </>
      );
    });

    return components;
  }, [links]);

  return (
    <SectionWrapper isFirstSection>
      <div className="deals-section-nav">{generateLinks()}</div>
      <CustomHeadingSection
        leftContent={<div className="deals-section__title-container">{leftContent}</div>}
        rightContent={
            <>
                {!image ? (
                    <div className="deals-section__speak-container">
                        <SecondaryTitle>Not sure? Speak to a mortgage advisor for free</SecondaryTitle>
                        <div className="deals-section__speak-button">
                        <Button
                            buttonText="Let's Speak"
                            height="48px"
                            handleClick={() => setModalFlow(true)}
                        />
                        </div>
                    </div>
                ): (
                    <div className="deals-section__image-container">
                        <img src={image} />
                    </div>
                )}
            </>
        }
        leftSize={isDesktop ? 7 : 6}
        rightSize={isDesktop ? 5 : 6}
      />
      <ModalsFlow 
        isFirstOpened={modalFlow}
        setIsFirstOpened={setModalFlow}
        firstModalIndex={1}
        usePureCalendlyWidget
      />
    </SectionWrapper>
  );
}

export default DealsHeading;
