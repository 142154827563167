import React from 'react';

function CustomHeadingSection({ imageReversed, leftContent, rightContent, leftSize = 6, rightSize = 6 }) {
  return (
    <section className="custom-heading__section">
      <div className="row">
        {imageReversed ? (
          <div className={`col-md-${rightSize} image`}>{rightContent}</div>
        ) : null}
        <div className={`col-md-${leftSize} content`}>{leftContent}</div>
        {!imageReversed ? (
          <div className={`col-md-${rightSize} image`}>{rightContent}</div>
        ) : null}
      </div>
    </section>
  );
}

export default CustomHeadingSection;
